import React from "react";
import moment from "moment";

// CLASSES
import Settings from "@classes/Settings";

// COMPONENTS
import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";
import DefaultFields from "../../../../components/DefaultFields/DefaultFields";
import ContentBox from "@components/ContentBox/ContentBox";
import Notice from "@components/Notice/Notice";
import Select from '@components/Select/Select';
import OptionsList from "../../../../components/OptionsList/OptionsList";
import Button from "@components/Button/Button";
import UploadAndSign from "../../../../components/UploadAndSign/UploadAndSign";
import IdentityNoInputField from "@cobComponents/IdentityNoInputField";

// HELPERS
import i18n from "@helpers/i18n";
import api from '@helpers/api';
import {
  md5,
  stringifyQueryParams,
  formatSSN,
  searchModules
} from "@helpers/utils";

// OTHER
import { inputIsCompany } from "../../Start.fulfillment";
import Validator from "@classes/Validator";


export default class InitSession extends React.PureComponent {
	fields = _getFields.call(this);

	render() {
		const {
      busy,
      partner,
      showHeader,
      customerState,
      assentlyStatus,
      nextStepOptions,
      futurDisabled,
      inline
    } = this.props;

    return (
      <>
        <DefaultFields fields={this.fields}/>
        <ContentBox className={inline ? "inline" : ""}>
          <div className="Cob-view1-fields">
            {showHeader && <h2>Rådgivning</h2>}
			<p className="Cob-description tightened">Ange kundens person-/organisationsnummer</p>
            <IdentityNoInputField
              name="customer_social_security_number_init"
              value={customerState.ssnOrg}
              label={i18n("cob", customerState.isCompany ? "customers_organizational_number" : "customers_social_security_number")}
              placeholder="12-siffrigt utan bindestreck"
              onChangeCallback={this._onSsnOrgChange}
            />

            <p className="Cob-miniNote">
              <strong>Exempel:</strong> <em>YYYYMMDDXXXX</em> och för företag <em>16XXXXXXXXXX</em>
            </p>

            {Validator.validateSsn(customerState.ssnOrg) && (
              <React.Fragment>
                <p className="Cob-description">Alternativ</p>
                <OptionsList
                  type="radio"
                  orientation="vertical"
                  options={nextStepOptions}
                  disabled={customerState.casesLoading}
                  onChange={this._onCaseTypeSelectChange}
                />

                {customerState.standaloneApplicationsChecked.length === 0 &&
                  (customerState.caseListFiltering === "drafts" ||
                    customerState.caseListFiltering === "sent-or-signed") && (
                    <div className="Cob-view1-cases">
                      {!customerState.cases ? (
                        <div className="loading">
                          <ActivityIndicator color="#91a0ad" scale={0.75} busy/>
                          <span>Laddar...</span>
                        </div>
                      ) : (
                        (customerState.caseListFiltering === "drafts" && customerState.caseListDrafts.length > 0) ||
                        (customerState.caseListFiltering === "sent-or-signed" && customerState.caseListOther.length > 0) ? (
                          <React.Fragment>
                            <Select
                              className="cases-select"
                              name="caseId"
                              label="Välj rådgivningstillfälle"
                              renderOption={this._renderCaseOption}
                              options={customerState.caseListFiltering === "drafts" ? customerState.caseListDrafts : customerState.caseListOther}
                              selectedIndex={customerState.selectedCaseIndex}
                              customFilterer={this._casesSelectFilterer}
                              onChange={this._onCasesSelectChange}
                            />

                            {customerState.showAltMessage && customerState.selectedCaseId !== null && !customerState.selectedCaseIsDraft && !customerState.selectedCaseIsRecalled && (
                              <Notice className="no-draft-notice"
                                  appearance="warning"
                                  message="Det senast signerade rådgivningstillfället har förvalts (om det finns ett sådant). Vill du utgå ifrån uppgifterna i ett annat rådgivningstillfälle, var god välj det i listan ovan."/>
                            )}

                            {customerState.selectedCaseId !== null && !customerState.selectedCaseIsDraft && customerState.selectedCaseIsRecalled && (
                              <Notice className="no-draft-notice"
                                  appearance="warning"
                                  message="Eftersom det valda rådgivningstillfället har återkallats så kommer ett nytt förifyllt rådgivningstillfälle att skapas baserat på dessa uppgifter."/>
                            )}

                          </React.Fragment>
                        ) : (
                          <p className="empty">
                            <em>Hittade inga tidigare rådgivningstillfällen.</em>
                          </p>
                        )
                      )}
                    </div>
                  )
                }
                
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    marginTop: "20px"                    
                  }}
                >
                  <Button
                    className="Cob-Footer-button"
                    dataInteractive="form-continue-btn"
                    type="submit"
                    label={i18n("general", "continue")}
                    appearance="secondary"
                    disabled={(customerState.selectedOption === "sent-or-signed" && !customerState.selectedCaseId) || (customerState.selectedOption === "drafts" && !customerState.selectedCaseId) || !customerState.selectedOption}
                    busy={busy}
                    style={{
                      width: "100%"                    
                    }}
                    filled
                  />
                </div>

                <div className="h-line spacing"/>

                <p className="Cob-description">
                  Skapa orderinstruktion utifrån signerad rådgivning
                </p>

                <div className="Cob-view1-cases" style={{paddingLeft: "0", marginTop: "5px", justifyContent: "flex-start"}}>
                  {!customerState.cases ? (
                    <div className="loading">
                      <ActivityIndicator color="#91a0ad" scale={0.75} busy/>
                      <span>Laddar...</span>
                    </div>
                  ) : (
                    <React.Fragment>
                      {customerState.caseListSigned.length <= 0 && (
                        <p className="empty">
                          <em>Hittade inga tidigare signerade rådgivningstillfällen.</em>
                        </p>
                      )}

                      {customerState.caseListSigned.length > 0 && (
                        <React.Fragment>
                          <Select
                            className="cases-select"
                            name="orderInstruktionCaseId"
                            label="Välj rådgivningstillfälle"
                            renderOption={this._rendeOrderInstruktionOption}
                            options={customerState.caseListSigned}
                            selectedIndex={customerState.orderInstruktionCaseIndex}
                            customFilterer={this._casesSelectFilterer}
                            onChange={this._onOrderinstruktionSelectChange}
                          />

                          <Button
                            className="Cob-Footer-button"
                            label="Gå vidare"
                            appearance={customerState.orderInstruktionCaseIndex > -1 ? "secondary" : "medium"}
                            disabled={customerState.orderInstruktionCaseIndex === -1}
                            busy={customerState.orderInstruktionLoading}
                            onClick={this._onOrderinstruktionSend}
                            filled
                            style={{marginTop: "10px"}}
                          />
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                </div>

                {!(futurDisabled) && (
                  <React.Fragment>
                    <div className="h-line spacing"/>

                    <p className="Cob-description">
                      Teckna kapitalförsäkring depåförsäkring (Futur)
                    </p>

                    <div className="Cob-view1-cases" style={{paddingLeft: "0", marginTop: "5px", justifyContent: "flex-start"}}>
                      {!customerState.cases ? (
                        <div className="loading">
                          <ActivityIndicator color="#91a0ad" scale={0.75} busy/>
                          <span>Laddar...</span>
                        </div>
                      ) : (
                        <React.Fragment>
                          {customerState.caseListDepaforsakring.length <= 0 && (
                            <p className="empty">
                              <em>Hittade inga tidigare signerade rådgivningstillfällen.</em>
                            </p>
                          )}

                          {customerState.caseListDepaforsakring.length > 0 && (
                            <React.Fragment>
                              <Select
                                className="cases-select"
                                name="futurCaseId"
                                label="Välj rådgivningstillfälle"
                                renderOption={this._renderCaseOption}
                                options={customerState.caseListDepaforsakring}
                                selectedIndex={customerState.futurCaseIndex}
                                customFilterer={this._casesSelectFilterer}
                                onChange={this._onFuturCaseSelectChange}
                              />

                              <Button
                                className="Cob-Footer-button"
                                label="Gå vidare"
                                appearance={customerState.futurCaseIndex > -1 ? "secondary" : "medium"}
                                disabled={customerState.futurCaseIndex === -1}
                                busy={customerState.futurCaseLoading}
                                onClick={this._onFuturCaseSend}
                                style={{marginTop: "10px"}}
                                filled
                              />
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                    <p style={{fontSize: "11px"}}>
                      <span onClick={this._onFuturCaseSend}
                          style={{cursor: "pointer", color: "#4cb7d5"}}>
                        Klicka här om KYC/AML är dokumenterad utanför detta system.
                      </span>
                    </p>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}

            {!searchModules(partner.modules, "UploadAndSign") && (
              <UploadAndSign partnerSsn={this.props.user.ssn}/>
            )}
          </div>

          {assentlyStatus && assentlyStatus.length > 0 && (
            <div style={{
              margin: "40px 0 0 0",
              background: "#FFF5F5",
              width: "100%",
              display: "block",
              padding: "20px",
              fontSize: "13px"
            }}>
              <strong style={{display: "block", padding: "0 0 5px 0"}}>
                Driftstörning Assently (digital signering)
              </strong>
              För närvarande påverkas följande system: {assentlyStatus.join(", ")} <br/><br/>
              <a href="https://assently.statuspage.io/" target="_blank" rel="noopener noreferrer">
                För mer information, klicka här
              </a>
            </div>
          )}
        </ContentBox>
      </>
    );
	}

  
	_onOrderinstruktionSend = () =>
	{
    const { customerState } = this.props;
    
		// Create a checksum that the cob odin client will check to make sure that the request
		// originated in a correct way.
		let dateString = new Date()
		dateString = dateString.toISOString().split('T')[0];
		dateString = dateString + new Date().getHours() + ":00";
		let checkString = md5(formatSSN(customerState.ssnOrg, {withDash: false}) + dateString + "citroneerclient");

		let partnerString = JSON.stringify({
			partner: this.props.user.getData(),
			customer: {id: formatSSN(customerState.ssnOrg, {withDash: false}), check: checkString},
			caseId: customerState.orderInstruktionCaseId
		});

		/**
		 * GLOF-1017 If the partner name or data contains non-latin1 characters we'll get an error.
		 * A workaround for this is to encode the string before encoding the string...
		 */
		let b64 = btoa(unescape(encodeURIComponent(partnerString)));
		window.location.href = this.props.partner.orderinstruktion + stringifyQueryParams({
			context: b64,
			token: Settings.get("AUTH_TOKEN"),
			key: "orderinstruktion"
		});
	}

	_onFuturCaseSend = () =>
	{
    const { customerState } = this.props;

		// Create a checksum that the cob odin client will check to make sure that the request
		// originated in a correct way.
		let dateString = new Date()
		dateString = dateString.toISOString().split('T')[0];
		dateString = dateString + new Date().getHours() + ":00";
		let checkString = md5(formatSSN(customerState.ssnOrg, {withDash: false}) + dateString + "citroneerclient");

		let partnerString = JSON.stringify({
			partner: this.props.user.getData(),
			customer: {id: formatSSN(customerState.ssnOrg, {withDash: false}), check: checkString},
			caseId: customerState.futurCaseId
		});

		/**
		 * GLOF-1017 If the partner name or data contains non-latin1 characters we'll get an error.
		 * A workaround for this is to encode the string before encoding the string...
		 */
		let b64 = btoa(unescape(encodeURIComponent(partnerString)));
		window.location.href = this.props.partner.futurcase + stringifyQueryParams({
			context: b64,
			token: Settings.get("AUTH_TOKEN"),
			key: "futur"
		});
	}

	// Internal methods
	_onCasesSelectChange = ({value, isDraft, isRecalled}, index) => {
    const { setCustomerState } = this.props;
		setCustomerState({
			selectedCaseId: value,
			selectedCaseIndex: index,
			selectedCaseIsDraft: isDraft,
			selectedCaseIsRecalled: isRecalled,
			showAltMessage: false
		});
	}

	_onFuturCaseSelectChange = ({value, isDraft, isRecalled}, index) => {    
    const { setCustomerState } = this.props;
    setCustomerState({
      futurCaseId: value,
      futurCaseIndex: index
    })
  };

	_onOrderinstruktionSelectChange = ({value, isDraft, isRecalled}, index) => {
    const { setCustomerState } = this.props;
    setCustomerState({
      orderInstruktionCaseId: value,
      orderInstruktionCaseIndex: index
    });
  }

  _onSsnOrgChange = (value, validated, valueCameFromPaste) =>
	{
    const { setCustomerState } = this.props;

    const newStateProps = {
      caseListFiltering: null,
      standaloneApplicationsChecked: [],
      casesLoading: false,
      cases: null,
      selectedCaseId: null,
      selectedCaseIndex: -1,
      selectedCaseIsDraft: null,
      selectedCaseIsRecalled: null,
      selectedOption: null,
      selectedWorkflow: "default"
		};

    if (validated)
		{
      setCustomerState({
				isCompany: inputIsCompany(formatSSN(value)),
				ssnOrg: valueCameFromPaste ? formatSSN(value) : value,
        ...newStateProps
      }, () =>
      {
        _getCustomerCases.call(this);
      });
    }
    else {
      setCustomerState({
				isCompany: false,
				ssnOrg: value,
        ...newStateProps
			});
    }
	}

	_onCaseTypeSelectChange = (checked, value, index) =>
	{
    const { partner, customerState, setCustomerState, onWorkflowSwitched } = this.props;
		let caseListFiltering = null;
		let selectedOption = null;
		let selectedWorkflow = "default";
		switch (index)
		{
			case 0:
				caseListFiltering = "new";
				selectedOption = "new";
				break;
			case 1:
				caseListFiltering = "sent-or-signed";
				selectedOption = "sent-or-signed";
				break;
			case 2:
				caseListFiltering = "drafts";
				selectedOption = "drafts";
				break;
			case 3:
				selectedWorkflow = "futur";
				selectedOption = "futur";
				break;
			case 4:
				selectedOption = "standalone";
				break;
			default:
				caseListFiltering = null;
				break;
		}

		const newStateProps = {
			caseListFiltering: caseListFiltering,
      selectedOption,
			standaloneApplicationsChecked: []
		};

		// Reset selected case
		newStateProps.selectedCaseId = null;
		newStateProps.selectedCaseIndex = -1;
		newStateProps.selectedCaseIsDraft = null;
		newStateProps.selectedCaseIsRecalled = null;
		newStateProps.showAltMessage = false;

		// If we want to continue with a signed case, select the last signed case and hide the case selection
		// dropdown. This is to make sure the default is to always use the last case. If the advisor wants to
		// select a different case they can expand the case selection list.
		if (caseListFiltering === "sent-or-signed")
		{
			const caseList = customerState.caseListOther;
			for (let i = 0; i < caseList.length; i++)
			{
				const customerCase = caseList[i];
				if (customerCase.isSigned && !customerCase.isRecalled)
				{
					newStateProps.selectedCaseId = customerCase.caseId;
					newStateProps.selectedCaseIndex = i;
					newStateProps.selectedCaseIsDraft = false;
					newStateProps.selectedCaseIsRecalled = false;
					newStateProps.showAltMessage = true;
					break;
				}
			}
		}

		if (caseListFiltering === null)
		{
			let applicationIndex = index - 4;
			if (applicationIndex >= 0)
			{
				const applications = partner.standaloneApplications;
				const app = applications[applicationIndex];
				newStateProps.standaloneApplicationsChecked.push(app.label);
			}
		}

		onWorkflowSwitched(selectedWorkflow, () => {
			setCustomerState(newStateProps, () =>
			{
				if ((caseListFiltering === "drafts" || caseListFiltering === "sent-or-signed") && !customerState.cases)
				{
					_getCustomerCases.call(this);
				}
			});
		});
	};

	_casesSelectFilterer = ({option}, inputValue) =>
	{
		inputValue = inputValue.toLowerCase();
		return option.label.indexOf(inputValue) === 0 || option.value.indexOf(inputValue) === 0;
	};

	_renderCaseOption = ({label, isDraft, isRecalled, isSigned, workflow}) =>
	{
		return (
			<div className="Cob-view1-caseOption">
				<div className="label">
					{label}
					{workflow === "futur" && (
						<span>(Enbart kapitalförsäkring)</span>
					)}
				</div>

				{isRecalled && (
					<div className="info recalled">Återkallat</div>
				)}

				{!isDraft && isSigned && (
					<div className="info signed">Signerat</div>
				)}

				{!isDraft && !isSigned && (
					<div className="info sent">Skickat</div>
				)}

				{isDraft && !isRecalled && (
					<div className="info draft">Utkast</div>
				)}
			</div>
		);
	};

	_rendeOrderInstruktionOption = ({label, isSigned, childCaseSentForSign, childCaseSigned}) =>
	{
		return (
			<div className="Cob-view1-caseOption">
				<div className="label">
					{label}
				</div>

				{isSigned && !childCaseSentForSign && !childCaseSigned && (
					<div className="info draft">Skapa orderinstruktion</div>
				)}

				{isSigned && childCaseSentForSign && !childCaseSigned && (
					<div className="info sent">Orderinstruktion skickad</div>
				)}

				{isSigned && childCaseSentForSign && childCaseSigned && (
					<div className="info signed">Orderinstruktion signerad</div>
				)}
			</div>
		);
	};
}

// PRIVATE FUNCTIONS
function _getCustomerCases()
{
  const { setCustomerState } = this.props;
	return new Promise((resolve) =>
	{
		setCustomerState({casesLoading: true}, async () =>
		{
			try
			{
				const {user, customerState} = this.props;

				const cases = await api("/case/customer-cases", {
					partnerSsn: user.ssn,
					customerId: formatSSN(customerState.ssnOrg, {withDash: false}),
					includeData: true
				});

				/**
				 * Sort cases according to when they were last modified
				 */
				cases.sort(function (a, b)
				{
					return b.createdOn - a.createdOn;
				});

				const casesDrafts = cases.filter(function (c)
				{
					return c.isDraft;
				});

				const casesOther = cases.filter(function (c)
				{
					// Filter cases that are the correct whitelabel and workflow
					if (c.whitelabel !== "citroneer" || c.workflow !== "default")
					{
						return false;
					}
					return !c.isDraft;
				});

				const casesSigned = cases.filter(function (c)
				{
					if (!c.isSigned)
					{
						return false;
					}

					// Filter only the case that contain an Orderinstruktion
					const caseData = JSON.parse(c.caseData);
					if (c.signDate && caseData.data && caseData.data.checkbox_advice_recommendation && caseData.data.checkbox_advice_recommendation === "yes")
					{
						if (caseData.data.send_welcome_email && caseData.data.send_welcome_email === "yes") // Kontoöppning should not show up here
						{
							return false;
						}

						// Filtrera de som redan har en signerad orderinstruktion, eller där utskicksdatumet är
						// mer än 60 dagar max gammalt
						const timeSinceSigned = Math.floor((Date.now() - c.signDate) / 1000);
						const sixtyDays = 86400 * 60;
						if (c.childCaseSigned || timeSinceSigned > sixtyDays)
						{
							return false;
						}
						return true;
					}
					return false;
				});

				const casesDepaForsakring = cases.filter(function (c)
				{
					if (!c.isSigned)
					{
						return false;
					}

					// Filter only the case that contain a Kontoöppning with Depåförsäkring
					const caseData = JSON.parse(c.caseData);
					if ((caseData.data &&
						caseData.data.checkbox_advice_onboarding &&
						caseData.data.checkbox_advice_onboarding === "yes" &&
						caseData.data.chk_accounttype_kf &&
						caseData.data.chk_accounttype_kf === "yes")  || c.workflow === "futur")
					{
						return true;
					}
					return false;
				});

				const caseListDrafts = casesDrafts.map(({createdOn, caseId, isDraft, isRecalled, isSigned, workflow}) => ({
					label: moment(createdOn).format("YYYY-MM-DD H:mm"),
					value: caseId,
					title: caseId,
					caseId,
					isDraft,
					isRecalled,
					isSigned,
					workflow
				}));

				const caseListOther = casesOther.map(({createdOn, caseId, isDraft, isRecalled, isSigned, workflow}) => ({
					label: moment(createdOn).format("YYYY-MM-DD H:mm"),
					value: caseId,
					title: caseId,
					caseId,
					isDraft,
					isRecalled,
					isSigned,
					workflow
				}));

				const caseListSigned = casesSigned.map(({createdOn, caseId, isDraft, isRecalled, isSigned, workflow, signDate, childCaseSentForSign, childCaseSigned}) => ({
					label: moment(createdOn).format("YYYY-MM-DD H:mm"),
					value: caseId,
					title: caseId,
					caseId,
					isDraft,
					isRecalled,
					isSigned,
					signDate,
					childCaseSentForSign,
					childCaseSigned,
					workflow
				}));

				const caseListDepaforsakring = casesDepaForsakring.map(({createdOn, caseId, isDraft, isRecalled, isSigned, workflow}) => ({
					label: moment(createdOn).format("YYYY-MM-DD H:mm"),
					value: caseId,
					title: caseId,
					caseId,
					isDraft,
					isRecalled,
					isSigned,
					workflow
				}));

				setCustomerState({
					cases,
					caseListDrafts,
					caseListOther,
					caseListSigned,
					caseListDepaforsakring,
					casesLoading: false
				}, () => resolve());
			}
			catch (e)
			{
				console.error(e.message);
				resolve();
			}
		});
	});
}

function _getFields()
{
	return [
		"checkbox_company",
		"customer_social_security_number"
	];
}